import SButton from '../ui/s-button'

export default {
  name: 'SendReportCard',
  components: {
    SButton,
  },
  props: {
    isArchive: {
      type: Boolean,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    report: {
      type: Object,
      required: true,
    },
  },
}
