import SendReportCard from '../../../../../components/SendReportCard'

export default {
  name: 'StepPreview',

  components: {
    SendReportCard,
  },

  props: {
    isArchive: {
      type: Boolean,
      required: true,
    },
    report: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async handleSendReport() {
      this.report.archive = this.isArchive
      this.$emit('submit')
    },
  },
}
